

























































import { Component, Vue } from "vue-property-decorator";
import { AttachmentHostType, BbsPostDto } from "@/api/appService";
import api from "@/api";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "BbsPostObjectDetail",
  components: {
    AttachmentsView,
  },
  filters: {},
})
export default class BbsPostDetail extends Vue {
  editId?: number;
  detail: BbsPostDto = {};
  loading = true;
  hostType = AttachmentHostType.BbsPost;

  created() {
    if (this.$route.params.id) {
      this.editId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.bbsPost.get({ id: this.editId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };
      console.log(res);
    });
    console.log(this.detail);
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
